import React from 'react';
import { Page } from '../components';
import useMedia from 'react-use/lib/useMedia';
import { Space, Background, Card } from '../components';
import { HeadLegal, TextLegal, Content, Section, UnorderedList, UnorderedListItem } from '../styles';
import styled, { css } from 'styled-components';
import { media } from '../theme';



const IndustryDetailPage = ({pageContext}) => {
    const { theme } = pageContext;
    const {heading, list, paragraphs, lastParagraph, slug} = pageContext.content;
    const isPhone = useMedia(`(max-width: ${theme.sizes.phone}px)`);
    console.log('pageContext :', pageContext);

	return <Page>
            <Content style={{height: '100vh'}}>
                <Section style={{height: '100%'}} id={slug}>
                    <Background 
                        color={theme.secondaryColor} 
                        padding={{phone: '19px 19px 55px 19px', def: '79px 60px 100px 18px'}}
                    >
                        <Card 
                            spacing={spacing} 
                            margin={{phone: '0',  def: '0 0 0 -40px'}}
                        >
                            <HeadLegal>{heading}</HeadLegal>
                            <Space height="20" />
                            {paragraphs && paragraphs.map(paragraph => (
                                    <TextLegal>{paragraph}</TextLegal>
                                ))}
                            {list && <UnorderedList>
                                    {list.map(item => (
                                        <UnorderedListItem>
                                            {item}
                                        </UnorderedListItem>
                                    ))}
                                </UnorderedList>}
                            {lastParagraph && <TextLegal>{lastParagraph}</TextLegal>}
                        </Card>
                    </Background>
                </Section>
            </Content>
        </Page>;
}

export default IndustryDetailPage;

const spacing = css`
    padding: 52px 50px 40px 50px;

    ${media.laptop`
        padding: 36px 42px 20px 42px;
    `}
    
    ${media.phone`
        padding: 36px 42px 20px 42px;
    `}
`;